import React, { useContext, useEffect, useState } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import PropTypes from "prop-types"
import { globalHistory } from "@reach/router"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import Menu from "./menu"
import Link from "../link"
import Arrow from "../../svg/arrow"
import { MainContext } from "../../../context/main-context"
import { TRANSLATIONS } from "../../../utils/translations"

const StyledHeader = styled.header`
  position: fixed;
  left: 0;
  width: 100%;
  display: flex;
  background: ${props => (props.$isHeaderWhite ? "#fff" : "transparent")};
  z-index: 50;
  padding: 2rem;
  transition:
    padding 200ms linear,
    background-color 100ms linear;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding: 4rem 3rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    padding: 7rem 5rem;
  }

  ${props =>
    props.menuFixed &&
    `
    background: #fff;
    transition: padding 200ms linear, background-color 200ms linear 200ms;
      
    > a{
      max-width: 12rem;
    }
    
    @media (min-width: ${props.theme.devices.desktop}) {
      padding: 1rem 2rem;
    }
  `}
`

const Logo = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 10rem;
  max-width: 10rem;
  max-height: 3.4rem;
  font-size: 0;
  transition: 200ms linear;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    max-width: 20rem;
  }

  .gatsby-image-wrapper,
  img {
    object-fit: contain !important;
    max-width: 100%;
  }
`

const LanguageMenu = styled.div`
  position: relative;
  top: -1rem;
  color: rgb(80, 80, 80);
  font-size: 2rem;
  font-family: "darker_grotesquebold", sans-serif;
  margin-left: 14%;
  transition: 200ms linear;

  &:after {
    position: absolute;
    top: -1rem;
    left: -3rem;
    right: -3rem;
    bottom: -6rem;
    background: ${props => props.theme.colors.gray2};
    content: "";
    opacity: 0;
    transition: 200ms linear;
    z-index: -1;
  }

  > span {
    display: none;
  }

  &:hover {
    &:after,
    .second-language {
      opacity: 1;
    }
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-left: 60%;

    > span {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
`

const SecondLanguage = styled.div`
  position: absolute;
  right: 2.3rem;
  bottom: -4rem;
  opacity: 0;
  text-align: right;
  transition: 200ms linear;

  a {
    color: #8a8a8a;
    text-transform: uppercase;
    text-decoration: none;
  }
`

const CurrentLanguage = styled.div`
  display: inline-block;
  color: ${props => props.theme.colors.text};
  text-transform: uppercase;

  svg {
    position: relative;
    top: 1.3rem;
    width: 1.5rem;
    margin-left: 1rem;
    transform: rotate(180deg);

    path {
      fill: #000;
    }
  }
`

const MenuButton = styled.button`
  display: block;
  position: absolute;
  top: 2rem;
  right: ${props => (props.isInMenu ? "3rem" : "2rem")};
  font-size: 0;
  height: 3rem;
  color: ${props =>
    props.isInMenu ? props.theme.colors.invertedText : props.theme.colors.text};
  font-family: "darker_grotesquebold", sans-serif;
  background: 0;
  padding-right: 7rem;
  border: 0;
  cursor: pointer;
  outline: none;
  z-index: 200;
  transition: 200ms linear;

  @media (min-width: ${props => props.theme.devices.mobileXXs}) {
    font-size: 2rem;
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    top: 4rem;
    right: ${props => (props.isInMenu ? "3rem" : "3rem")};
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    top: 7rem !important;
    right: ${props => (props.isInMenu ? "3rem" : "5rem")};
  }

  ${props =>
    props.menuFixed &&
    `
    top: 2rem !important;
    
    @media (min-width: ${props.theme.devices.tablet}) {
      top: 0.9rem !important;
    }
  `}

  &:focus,
  &:active {
    outline: none;
  }

  > span {
    @media (min-width: ${props => props.theme.devices.mobile}) {
      &:first-of-type {
        display: inline;
      }
    }

    &:last-child {
      position: absolute;
      top: 0.5rem;
      height: 100%;

      ${props =>
        !props.isInMenu &&
        `
              right: 5rem;
            `};

      span,
      &:before {
        position: absolute;
        top: 1.8rem;
        left: 0;
        width: ${props => (props.isInMenu ? "5rem" : "2.5rem")};
        border-bottom: 0.3rem solid
          ${props =>
            props.isInMenu
              ? props.theme.colors.invertedText
              : props.theme.colors.text};
        content: "";
        transition:
          border-bottom-color 200ms linear,
          width 200ms linear;
      }

      &:before {
        top: 0.5rem;
        width: 5rem;
      }
    }
  }
`

const OverlayMenu = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 70rem;
  max-width: 100%;
  height: 100vh;
  color: ${props => props.theme.colors.invertedText};
  background-color: ${props => props.theme.colors.text};
  z-index: 10000;

  ${props =>
    props.$isOpen
      ? `right:0;  transition: right 600ms cubic-bezier(0.25, 1, 0.5, 1);`
      : `
          right:-70rem; transition: right 600ms cubic-bezier(0.64, 0, 0.78, 0);`}
`

const MenuClose = styled.button`
  position: fixed;
  top: -10rem;

  ${props =>
    props.$isVisible &&
    `
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
    opacity:0;
  `}
`

const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Header = ({ menuItems = [], isHeaderWhite }) => {
  const { currentPage } = useContext(MainContext)
  const [menuFixed, setMenuFixed] = useState()
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setMenuOpen(false)
      }
    })
  }, [setMenuOpen])

  useEffect(() => {
    const st = ScrollTrigger.create({
      start: "top -80",
      end: 99999,
      onUpdate: self => {
        setMenuFixed(self.isActive)
      },
    })

    return () => {
      st.kill()
    }
  }, [currentPage])

  return (
    <StyledHeader $isHeaderWhite={isHeaderWhite} menuFixed={menuFixed}>
      <Logo url="/">
        <StaticImage
          src="../../../images/logo-waynet.png"
          alt="Waynet"
          placeholder="none"
          layout="fixed"
          width={200}
          height={34}
        />
      </Logo>
      <LanguageMenu className="language-menu">
        <span>
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].language}:{" "}
        </span>
        <CurrentLanguage>
          {process.env.GATSBY_CONTENTFUL_LOCALE}
          <Arrow />
        </CurrentLanguage>
        <SecondLanguage className="second-language">
          <a
            href={
              process.env.GATSBY_CONTENTFUL_LOCALE === "pl"
                ? process.env.GATSBY_EN_URL
                : process.env.GATSBY_PL_URL
            }
          >
            {process.env.GATSBY_CONTENTFUL_LOCALE === "pl" ? "eng" : "pl"}
          </a>
        </SecondLanguage>
      </LanguageMenu>
      <MenuButton
        onClick={toggleMenu}
        menuFixed={menuFixed}
        aria-label={TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].openMenu}
      >
        Menu
        <span>
          <span></span>
        </span>
      </MenuButton>
      <OverlayMenu $isOpen={menuOpen}>
        <MenuButton
          onClick={toggleMenu}
          isInMenu={true}
          aria-label={
            TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].closeMenu
          }
        >
          <span>
            <span></span>
          </span>
        </MenuButton>
        <Navigation>
          <Menu menuItems={menuItems} />
        </Navigation>
      </OverlayMenu>
      <MenuClose
        onClick={toggleMenu}
        $isVisible={menuOpen}
        aria-label={
          TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].closeMenu
        }
      />
    </StyledHeader>
  )
}

Header.propTypes = {
  menuItems: PropTypes.array,
  isHeaderWhite: PropTypes.object,
}

export default Header
