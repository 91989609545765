import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { TRANSLATIONS } from "../../utils/translations"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ColumnHeader from "./column-header"
import ScrollButton from "../common/scroll-button"
import PhoneSvg from "../svg/phone"
import EmailSvg from "../svg/email"
import Arrow from "../svg/arrow"
import Link from "./link"
import { MainContext } from "../../context/main-context"
import { INLINES } from "@contentful/rich-text-types"
import Phone from "../svg/phone"
import Email from "../svg/email"

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background: #000;
  color: #fff;
  font-size: 2.8rem;
  line-height: 4.8rem;
  padding: 0 5rem 4rem;
`

const FooterColumns = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  padding-top: 8rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    grid-template-columns: 1fr 1fr 2fr 1fr;
  }
`

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: ${props => props.theme.colors.invertedText};
    text-decoration: none;
    padding-left: 0;
    font-family: "darker_grotesquelight";
    margin-bottom: 0;
  }
`

const FooterContactData = styled.div`
  display: grid;
  height: 16rem;
  font-size: 2.4rem;
  margin: 6rem 0 3rem;
  border: 0.3rem solid ${props => props.theme.colors.invertedText};

  @media (min-width: ${props => props.theme.devices.desktop}) {
    grid-template-columns: 1fr 1fr;
    font-size: 4.8rem;
    margin: 25rem 0 18rem;
  }

  a {
    color: ${props => props.theme.colors.invertedText};
    text-decoration: none;
    padding-left: 0;
    font-family: "darker_grotesquelight";
    margin-bottom: 0;
  }

  svg {
    position: relative;
    top: 0.6rem;
    margin-right: 2.5rem;
  }
`

const FooterContactPhone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
`

const FooterContactEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0.3rem solid ${props => props.theme.colors.invertedText};
  padding-bottom: 1rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    border-top: 0;
    border-left: 0.3rem solid ${props => props.theme.colors.invertedText};
  }
`

const FooterBottomBar = styled.div`
  display: grid;
  color: ${props => props.theme.colors.text2};
  font-size: 1.8rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`

const FooterCopyrights = styled.p`
  display: flex;
`

const FooterList = styled.ul`
  display: flex;
  flex-direction: column;

  ${props =>
    props.$isBottom &&
    `
      flex-direction: row;
      margin-top:3rem;
      
      @media (min-width: ${props.theme.devices.desktop}) {
        margin-top:0;
      }
  `}
`

const StyledScrollButton = styled(ScrollButton)`
  color: ${props => props.theme.colors.invertedText};
  font-size: 1.8rem;
  font-family: "darker_grotesquelight", sans-serif;
  margin-top: 5rem;
  margin-left: auto;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 2.8rem;
    margin-top: 0;
  }

  svg {
    position: relative;
    top: 0.7rem;
    margin-right: 2rem;

    path {
      fill: ${props => props.theme.colors.main};
    }
  }
`

gsap.registerPlugin(ScrollTrigger)

const Footer = ({ footerData, projectsSlug }) => {
  const { currentPage } = useContext(MainContext)

  useEffect(() => {
    gsap.set("footer", { yPercent: -30 })

    const uncover = gsap.timeline({ paused: true })

    uncover.to("footer", { yPercent: 0, ease: "none" })

    const st = ScrollTrigger.create({
      trigger: "main",
      start: "bottom bottom",
      end: "+=50%",
      animation: uncover,
      scrub: true,
    })

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 200)

    return () => {
      uncover.kill()
      st.kill()
      gsap.set("footer", { yPercent: 0 })
    }
  }, [currentPage])

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <a href={data.uri} rel="nofollow">
          {children}
        </a>
      ),
    },
  }

  if (!footerData) return

  return (
    <StyledFooter>
      <FooterColumns>
        {footerData.firstColumnLinks && (
          <FooterColumn>
            <ColumnHeader>{footerData.firstColumnTitle}</ColumnHeader>
            <FooterList>
              {footerData.firstColumnLinks
                .filter(item => item.contentful_id)
                .map(item => (
                  <li key={item.contentful_id}>
                    <Link url={`/${item.slug}`}>{item.title}</Link>
                  </li>
                ))}
            </FooterList>
          </FooterColumn>
        )}
        {footerData.secondColumnLinks && (
          <FooterColumn>
            <ColumnHeader>{footerData.secondColumnTitle}</ColumnHeader>
            <FooterList>
              {footerData.secondColumnLinks.map(item => (
                <li key={item.contentful_id}>
                  <Link url={`/${projectsSlug}/${item.slug}`}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </FooterList>
          </FooterColumn>
        )}
        <FooterColumn>
          <ColumnHeader>{footerData.thirdColumnTitle}</ColumnHeader>
          {documentToReactComponents(
            JSON.parse(footerData.thirdColumnText.raw),
          )}
        </FooterColumn>
        <FooterColumn>
          <ColumnHeader>{footerData.fourthColumnTitle}</ColumnHeader>
          {documentToReactComponents(
            JSON.parse(footerData.fourthColumnText.raw),
            options,
          )}
        </FooterColumn>
      </FooterColumns>
      <FooterContactData>
        <FooterContactPhone>
          <PhoneSvg />
          <a
            href={`tel:${footerData.telephoneNumber}`}
            rel="noopener noreferrer"
          >
            {footerData.telephoneNumber}
          </a>
        </FooterContactPhone>
        <FooterContactEmail>
          <EmailSvg />
          <a
            href={`mailto:${footerData.addressEmail}`}
            rel="noopener noreferrer"
          >
            {footerData.addressEmail}
          </a>
        </FooterContactEmail>
      </FooterContactData>
      <FooterBottomBar>
        <FooterCopyrights>
          © {new Date().getFullYear()} {footerData.copyrightsText}
        </FooterCopyrights>
        {footerData.bottomLinks && (
          <FooterList $isBottom={true}>
            {footerData.bottomLinks.map(item => (
              <li key={item.contentful_id}>
                <Link url={`/${item.slug}`}>{item.title}</Link>
              </li>
            ))}
          </FooterList>
        )}
        <StyledScrollButton isSmall={true}>
          <Arrow />{" "}
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].scrollUp}
        </StyledScrollButton>
      </FooterBottomBar>
    </StyledFooter>
  )
}

Footer.propTypes = {
  footerData: PropTypes.object,
  projectsSlug: PropTypes.string,
}

export default Footer
