import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { MainContext } from "../../context/main-context"
import CookieConsent from "react-cookie-consent"
import { TRANSLATIONS } from "../../utils/translations"

const StyledMain = styled.main`
  overflow: hidden;
  position: relative;
  background: ${props => props.pageColor};
  z-index: 10;
  transition: background 100ms ${props => props.theme.easing};

  @media (min-width: ${props => props.theme.devices.mobile}) {
    transition: background 800ms ${props => props.theme.easing};
  }

  + .CookieConsent {
    display: flex !important;

    @media (max-width: ${props => props.theme.devices.mobile}) {
      left: 0 !important;
      width: 100% !important;

      > div {
        flex: 0 1 100% !important;
      }
    }
  }
`

const CookieConsentContent = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.9rem;

  p {
    font-size: 2.3rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

const Main = ({ children, location }) => {
  const { pageColor, setCurrentPage } = useContext(MainContext)

  useEffect(() => {
    if (!setCurrentPage || !location) return

    setCurrentPage(location)
  }, [location, setCurrentPage])

  return (
    <>
      <StyledMain pageColor={pageColor}>{children}</StyledMain>

      <CookieConsent
        cookieName="gatsby-gdpr-google-tagmanager"
        style={{ background: "#F0F0F0", left: "20%", width: "60%" }}
        contentStyle={{ margin: "1rem 3rem 2rem" }}
        buttonStyle={{
          color: "#fff",
          background: "#000",
          fontSize: "2rem",
          padding: "0.3rem 1rem 0.6rem",
        }}
        declineButtonStyle={{
          color: "#fff",
          fontSize: "2rem",
          padding: "0.3rem 1rem 0.6rem",
        }}
        buttonText={`${
          TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].accept
        }`}
        ariaAcceptLabel={`${
          TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].accept
        }`}
        declineButtonText={`${
          TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].reject
        }`}
        ariaRejectLabel={`${
          TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].reject
        }`}
        enableDeclineButton={true}
        expires={150}
      >
        <CookieConsentContent>
          <p>
            {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].cookiesText}
          </p>
          {
            TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE]
              .cookiesDescription
          }
        </CookieConsentContent>
      </CookieConsent>
    </>
  )
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  location: PropTypes.object,
}

export default Main
