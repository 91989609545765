const TRANSLATIONS = {
  pl: {
    language: "Język",
    whoWeWork: "Zobacz dla kogo pracujemy",
    andMore: "I więcej",
    moreAboutUs: "Więcej o nas",
    contactUs: "Skontaktuj się z nami",
    allProjects: "Wszystkie projekty",
    allPosts: "Wszystkie wpisy",
    readMore: "Czytaj dalej",
    scrollUp: "Do góry",
    seeMorePosts: "Zobacz pozostałe tematy",
    contactInfo: "Formularz kontaktowy i dane teleadresowe",
    seeOnline: "Zobacz online",
    projectInformation: "Informacje o projekcie",
    type: "Typ",
    scope: "Zakres prac",
    filterTitle: "Wybierz jedna z naszych realizacji i dowiedz się więcej",
    all: "Wszystkie",
    recentlyLooking: "Aktualnie poszukujemy",
    otherOffers: "Pozostałe oferty",
    requiredField: "Pole jest wymagane",
    name: "Imię",
    projectName: "Nazwa projektu",
    email: "Adres e-mail",
    wrongFormat: "Zły format",
    telephone: "Telefon",
    message: "Wiadomość",
    accept: "Akceptuję",
    reject: "Odmawiam",
    privacyPolicy: "politykę prywatnośc",
    formSubmitError: "Coś poszło nie tak. Spróbuj jeszcze raz później.",
    sendMessage: "Wyślij wiadomość",
    pageNotFound: "Strona nie została znaleziona",
    cookiesText: "Niniejsza strona korzysta z plików cookie",
    cookiesDescription:
      "Wykorzystujemy pliki cookie do spersonalizowania treści i reklam, aby oferować funkcje społecznościowe i analizować ruch w naszej witrynie.",
    openMenu: "Otwórz menu",
    closeMenu: "Zamknij menu",
    prevProject: "Poprzedni projekt",
    nextProject: "Następny projekt",
  },
  en: {
    language: "Language",
    whoWeWork: "See who we work for",
    andMore: "And more",
    moreAboutUs: "More about us",
    contactUs: "Contact with us",
    allProjects: "All projects",
    allPosts: "All posts",
    readMore: "Read more",
    scrollUp: "Scroll to top",
    seeMorePosts: "See more posts",
    contactInfo: "Contact form and address data",
    seeOnline: "See online",
    projectInformation: "Information about project",
    type: "Type",
    scope: "Scope",
    filterTitle: "Select one of our projects and read more",
    all: "All",
    recentlyLooking: "Recently looking",
    otherOffers: "Other offers",
    requiredField: "Field is required",
    name: "Name",
    projectName: "Project name",
    email: "Email",
    wrongFormat: "Wrong format",
    telephone: "Telephone",
    message: "Message",
    accept: "Accept",
    reject: "Reject",
    privacyPolicy: "privacy policy",
    formSubmitError: "Something went wrong. Try again later",
    sendMessage: "Send a message",
    pageNotFound: "Page not found",
    cookiesText: "This website uses cookies",
    cookiesDescription:
      "We use cookies to personalize content and ads, to offer social features and to analyze traffic on our site.",
    openMenu: "Open menu",
    closeMenu: "Close menu",
    prevProject: "Previous project",
    nextProject: "Next project",
  },
}

module.exports = {
  TRANSLATIONS,
}
