import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import ArrowSvg from "../svg/arrow"

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: "darker_grotesquebold", sans-serif;
  color: ${props => props.theme.colors.text};

  &.is-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26rem;
    height: 7rem;
    font-size: 2.4rem;
    font-family: "darker_grotesquebold", sans-serif;
    text-decoration: none;
    color: ${props => props.theme.colors.invertedText};
    background: ${props => props.theme.colors.text};
    border-radius: 1rem;
    border: 0.2rem solid ${props => props.theme.colors.text};
    padding-bottom: 0.2rem;

    &.has-border {
      border: 0.2rem solid ${props => props.theme.colors.invertedText};
    }
  }
`

const LinkComponent = ({
  children = "",
  url = "/",
  className = "",
  isButton = false,
  hasStartArrow = false,
  hasEndArrow = false,
  hasBorder = true,
  ariaLabel = "",
  rel = "",
}) => {
  return (
    <StyledLink
      to={url.slice(-1) === "/" ? url : `${url}/`}
      className={`${className} ${isButton ? "is-button" : ""} ${
        hasBorder ? "has-border" : ""
      }`}
      activeClassName="active"
      aria-label={ariaLabel}
      rel={rel}
    >
      {hasStartArrow && <ArrowSvg />}
      {children}
      {hasEndArrow && <ArrowSvg />}
    </StyledLink>
  )
}

LinkComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  url: PropTypes.string,
  onMouseMove: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  isButton: PropTypes.bool,
  hasStartArrow: PropTypes.bool,
  hasEndArrow: PropTypes.bool,
  hasBorder: PropTypes.bool,
  ariaLabel: PropTypes.string,
  rel: PropTypes.string,
}

export default LinkComponent
