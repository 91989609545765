import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Link from "../link"

const MenuElement = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30rem;
  list-style-type: none;
  margin: 0 auto;
  z-index: 2;
`

const MenuItem = styled.li`
  overflow: hidden;
  padding-bottom: 0.5rem;

  a {
    position: relative;
    top: -0.2rem;
    color: ${props => props.theme.colors.invertedText};
    text-decoration: none;
  }
`

const MainMenuLink = styled(Link)`
  font-size: 6rem;
  line-height: 6rem;
  font-family: "darker_grotesquebold", sans-serif;
`

const MainMenuLabel = styled.p`
  font-size: 6rem;
  line-height: 6rem;
  font-family: "darker_grotesquebold", sans-serif;
`

const Submenu = styled.ul`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`

const SubmenuItem = styled.li`
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 4rem;
`

const SubMenuLink = styled(Link)`
  font-size: 2.2rem;
  line-height: 2.5rem;
`

const Menu = ({ menuItems = [] }) => {
  return (
    <MenuElement>
      {menuItems &&
        menuItems
          .filter(item => item.node.topLevel && !item.node.hidden)
          .sort((a, b) => a.node.order - b.node.order)
          .map(menuItem => (
            <MenuItem key={menuItem.node.contentful_id}>
              {menuItem.node.reference ? (
                <MainMenuLink url={`/${menuItem.node.reference.slug}/`}>
                  {menuItem.node.title}
                </MainMenuLink>
              ) : (
                <MainMenuLabel>{menuItem.node.title}</MainMenuLabel>
              )}

              {menuItem.node.submenu && (
                <Submenu>
                  {menuItem.node.submenu.map(
                    submenuItem =>
                      submenuItem.reference &&
                      !submenuItem.reference.hidden && (
                        <SubmenuItem key={submenuItem.contentful_id}>
                          <SubMenuLink url={`/${submenuItem.reference.slug}/`}>
                            {submenuItem.reference.title}
                          </SubMenuLink>
                        </SubmenuItem>
                      ),
                  )}
                </Submenu>
              )}
            </MenuItem>
          ))}
    </MenuElement>
  )
}

Menu.propTypes = {
  menuItems: PropTypes.array,
}

export default Menu
