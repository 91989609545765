import React from "react"
import PropTypes from "prop-types"

const Phone = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28.703"
      height="28.703"
      viewBox="0 0 28.703 28.703"
    >
      <path
        d="M220.7,150.669a6.729,6.729,0,0,1-.2,1.437,8.1,8.1,0,0,1-.428,1.4,5.466,5.466,0,0,1-2.487,2.161,7.557,7.557,0,0,1-4.862.968,8.813,8.813,0,0,1-1.172-.255q-.652-.183-.968-.3t-1.131-.418q-.815-.306-1-.367a18.464,18.464,0,0,1-3.568-1.692,34.243,34.243,0,0,1-9.785-9.785,18.464,18.464,0,0,1-1.692-3.568q-.061-.183-.367-1t-.418-1.131q-.112-.316-.3-.968a8.813,8.813,0,0,1-.255-1.172,7.557,7.557,0,0,1,.968-4.862,5.466,5.466,0,0,1,2.161-2.487,8.1,8.1,0,0,1,1.4-.428,6.729,6.729,0,0,1,1.437-.2,1.142,1.142,0,0,1,.428.061q.367.122,1.08,1.549.224.387.612,1.1t.714,1.294q.326.581.632,1.091.061.082.357.51a5.692,5.692,0,0,1,.438.724,1.33,1.33,0,0,1,.143.581,1.692,1.692,0,0,1-.581,1.019,9.938,9.938,0,0,1-1.264,1.121,12.276,12.276,0,0,0-1.264,1.08,1.553,1.553,0,0,0-.581.938,1.378,1.378,0,0,0,.1.459,4.455,4.455,0,0,0,.173.418q.071.143.285.489t.234.387a20.2,20.2,0,0,0,8.338,8.338q.041.02.387.234t.489.285a4.455,4.455,0,0,0,.418.173,1.378,1.378,0,0,0,.459.1,1.553,1.553,0,0,0,.938-.581,12.276,12.276,0,0,0,1.08-1.264,9.937,9.937,0,0,1,1.121-1.264,1.692,1.692,0,0,1,1.019-.581,1.33,1.33,0,0,1,.581.143,5.692,5.692,0,0,1,.724.438q.428.3.51.357.51.306,1.091.632t1.294.714q.714.387,1.1.612,1.427.713,1.549,1.08A1.142,1.142,0,0,1,220.7,150.669Z"
        transform="translate(-192 -128)"
        fill="#fff"
      />
    </svg>
  )
}

Phone.propTypes = {
  className: PropTypes.string,
}

export default Phone
