import React from "react"
import PropTypes from "prop-types"

const ArrowBig = ({ className }) => {
  return (
    <svg
      className={`arrow-big ${className}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="93.721"
      height="99.176"
      viewBox="0 0 93.721 99.176"
    >
      <path
        d="M-40.894,38.449-29.58,27.135-1.787,54.928v-68.8h16v68.3l27.3-27.3L52.827,38.449,5.966,85.309Z"
        transform="translate(40.894 13.867)"
      />
    </svg>
  )
}

ArrowBig.propTypes = {
  className: PropTypes.string,
}

export default ArrowBig
