import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const ColumnHeaderElement = styled.p`
  display: flex;
  font-family: "darker_grotesquebold", sans-serif;
  color: ${props => props.theme.colors.text2};
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 0.4rem;
`

const ColumnHeader = ({ className, children }) => {
  return (
    <ColumnHeaderElement className={className}>{children}</ColumnHeaderElement>
  )
}

ColumnHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  className: PropTypes.string,
}

export default ColumnHeader
