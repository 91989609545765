import React, { createContext, useState } from "react"

const MainContext = createContext()

const MainProvider = ({ children }) => {
  const [pageColor, setPageColor] = useState()
  const [currentPage, setCurrentPage] = useState()

  return (
    <MainContext.Provider
      value={{
        pageColor,
        setPageColor,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export { MainContext }

export default MainProvider
