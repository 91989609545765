import React from "react"
import PropTypes from "prop-types"

const Arrow = ({ className }) => {
  return (
    <svg
      className={className}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="31.757"
      height="34.19"
      viewBox="0 0 31.757 34.19"
    >
      <path
        d="M1124.239,8461.324c-4.517,0-8.082-1.348-10.6-4.007-3.8-4.021-3.714-9.6-3.686-10.315v-12.217l-7.653,7.653-2.828-2.829,12.476-12.476,12.476,12.476-2.828,2.829-7.641-7.641v12.312l0,.043c-.006.157-.147,4.538,2.618,7.438,1.729,1.813,4.309,2.733,7.668,2.733h6.993v4Z"
        transform="translate(-1099.474 -8427.134)"
        fill="#fff"
      />
    </svg>
  )
}

Arrow.propTypes = {
  className: PropTypes.string,
}

export default Arrow
