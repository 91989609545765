import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@emotion/react"
import Footer from "../../src/components/common/footer"
import Header from "../../src/components/common/header/header"
import Main from "../../src/components/common/main"
import Theme from "../theme/theme"
import "./layout.css"
import MainProvider from "../context/main-context"

const Layout = props => {
  return (
    <ThemeProvider theme={Theme}>
      <MainProvider>
        <Header
          menuItems={props.pageContext.menuItems}
          isHeaderWhite={
            props.pageContext.aboutUsPage ||
            props.pageContext.service ||
            props.pageContext.project
          }
        />
        <Main location={props.location}>{props.children}</Main>
        <Footer
          footerData={props.pageContext.footerData}
          projectsSlug={props.pageContext.projectsPageSlug}
        />
      </MainProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
