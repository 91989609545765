const devices = {
  desktopLarge: "1700px",
  desktopMedium: "1440px",
  desktop: "1224px",
  tablet: "1023px",
  mobile: "769px",
  mobileXs: "700px",
  mobileXxs: "370px",
}

export default devices
