import React from "react"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { gsap } from "gsap"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import ArrowBig from "../svg/arrow-big"

const ScrollButtonElement = styled.button`
  ${props =>
    props.$isSmall
      ? `
    background: none;
    border:none;
    cursor:pointer;
  `
      : `
    position: relative;
    font-size: 2.8rem;
    line-height: 3.2rem;
    font-family: "darker_grotesquebold", sans-serif;
    color: inherit;
    text-align: center;
    background: none;
    margin-top: 5rem;
    border: 0;
    cursor: pointer;
    
     svg {
     display: block;
      margin-top: 3rem;
      max-width: 4rem;
      margin: 0 auto;
     }
    
    @media (min-width: ${props.theme.devices.desktop}) {
      text-align: right;

      margin-right: 9rem;
      padding-right: 12rem;
      
      svg {
        position: absolute;
        top: 50%;
        right: 0;
        max-width: 9rem;
        margin: -4rem 0 0;
      }
    }
  `}
`

gsap.registerPlugin(ScrollToPlugin)

const ScrollButton = ({ children, className, sectionId, isSmall = false }) => {
  const scrollToSection = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: `#${sectionId}`, offsetY: 60 },
    })
  }

  return (
    <ScrollButtonElement
      className={className}
      $isSmall={isSmall}
      onClick={() => scrollToSection()}
    >
      {children}
      {sectionId && <ArrowBig />}
    </ScrollButtonElement>
  )
}

ScrollButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  sectionId: PropTypes.string,
  isSmall: PropTypes.bool,
}

export default ScrollButton
