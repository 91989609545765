import devices from "./devices"

const theme = {
  colors: {
    main: "#E73080",
    second: "#ACE0EC",
    third: "#413D6E",
    fourth: "#B6EAFA",
    text: "#000",
    text2: "#959595",
    invertedText: "#fff",
    gray: "#313131",
    gray2: "#EDEDED",
    gray3: "#989898",
    gray4: "#BABABA",
    red: "#E65353",
  },
  easing: "cubic-bezier(0, 0.55, 0.45, 1)",

  devices: {
    ...devices,
  },
}

export default theme
