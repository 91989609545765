import React from "react"
import PropTypes from "prop-types"

const Email = ({ className }) => {
  return (
    <svg
      className={className}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="29.646"
      height="29.563"
      viewBox="0 0 29.646 29.563"
    >
      <path
        d="M30.878,15.481,4.365,2.151A1.569,1.569,0,0,0,2.144,4.076L5.846,14l16.885,2.814L5.846,19.628l-3.7,9.924a1.5,1.5,0,0,0,2.222,1.777L30.878,18A1.407,1.407,0,0,0,30.878,15.481Z"
        transform="translate(-2.009 -1.994)"
        fill="#fff"
      />
    </svg>
  )
}

Email.propTypes = {
  className: PropTypes.string,
}

export default Email
